import React from 'react';

const LogoEn = () => {
  return (
    <svg width="88" height="40" viewBox="0 0 88 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.8374 13C14.6272 13 17.5193 14.8172 16.2396 19.6033L14.32 26.7441H10.0714L11.991 19.6033C12.6308 17.223 11.0952 16.6344 9.91783 16.6344C8.56134 16.6344 7.63995 17.4022 7.05129 19.245L5.05494 26.7441H0.755127L4.3895 13.2815H7.63995L7.99827 14.7148C8.91966 13.6143 10.1226 13 11.8374 13Z" fill="black"/>
      <path d="M30.6771 21.2925H20.9513C20.7977 22.5978 22.0519 23.4936 23.5363 23.4936C24.9952 23.4936 25.9166 22.8793 26.6076 21.9835L30.3444 22.521C29.167 25.234 26.2237 27 23.2036 27C18.8526 27 16.242 24.0567 16.9842 19.7569C17.6753 15.841 20.9257 13 24.8928 13C29.1158 13 31.88 15.6362 30.6771 21.2925ZM24.5857 16.404C23.0244 16.404 21.8471 17.2742 21.4888 18.5027H26.8892C27.0427 17.223 26.0445 16.404 24.5857 16.404Z" fill="black"/>
      <path d="M48.9859 13.2815H53.5673L46.0426 26.7441H41.7172C41.6916 21.4205 41.7172 19.5009 41.9475 18.1188C41.41 19.5009 40.3607 21.4461 37.4429 26.7441H33.1175L32.7848 13.2815H36.9822C36.8799 18.6819 36.8287 20.5503 36.5471 22.0091C37.1102 20.5247 38.0572 18.6563 40.9237 13.2815H45.1724C45.1468 18.426 45.1212 20.1664 44.8652 21.702C45.3771 20.3199 46.3497 18.426 48.9859 13.2815Z" fill="black"/>
      <path d="M68.1311 13C71.8935 13 74.5553 15.1755 73.3779 19.5777L71.4584 26.7441H67.2097L69.2573 19.117C69.8203 17.0439 68.4639 16.6344 67.5937 16.6344C66.2116 16.6344 65.3926 17.5814 64.8551 19.5777L62.9355 26.7441H58.6869L60.7344 19.117C61.2719 17.1207 59.8642 16.6344 58.9428 16.6344C57.6119 16.6344 56.8953 17.5046 56.3322 19.5777L54.4127 26.7441H50.164L52.2116 19.117C53.3889 14.7148 55.9227 13 59.2244 13C61.2975 13 62.7052 13.7422 63.473 14.766C64.6503 13.6654 65.9812 13 68.1311 13Z" fill="black"/>
      <path d="M87.717 21.2925H77.9913C77.8377 22.5978 79.0918 23.4936 80.5763 23.4936C82.0351 23.4936 82.9565 22.8793 83.6476 21.9835L87.3843 22.521C86.207 25.234 83.2637 27 80.2436 27C75.8925 27 73.2819 24.0567 74.0242 19.7569C74.7152 15.841 77.9657 13 81.9328 13C86.1558 13 88.92 15.6362 87.717 21.2925ZM81.6256 16.404C80.0644 16.404 78.8871 17.2742 78.5287 18.5027H83.9291C84.0827 17.223 83.0845 16.404 81.6256 16.404Z" fill="black"/>
    </svg>
  );
};

export default LogoEn;
