import React from 'react'
import GradientButton from './GradientButton'
import {Trans, useTranslation} from "react-i18next";

const ParallaxedBottomBlock = () => {
  const { i18n } = useTranslation();
  const russian = i18n.language.startsWith('ru')
  return (
    <section className="sticky z-1 top-[35%] flex flex-col items-center justify-center mb-[120px] lg:mb-[200px] xl:mb-[20px]">
      <h2 className={`text-center sm:text-[48px] xl:text-[56px] sm:leading-[56px] xl:leading-[64px] text-[30px] leading-[38px] 
        font-semibold md:font-bold w-full mt-6 px-6 pb-6 lg:pb-8 xl:max-w-[658px] sm:max-w-[608px] max-w-[370px] 
        ${!russian && "max-w-[250px] sm:max-w-[430px] xl:max-w-[400px]"}`}>
        <Trans i18nKey="getaccess">Получить ранний доступ
        прямо сейчас</Trans></h2>
      <GradientButton />
    </section>
  )
}

export default ParallaxedBottomBlock