import React, {useEffect, useState} from 'react';
import {Egg, EggVideo} from "../assets";
import Logo from "../components/Common/Logo";
import ParallaxedHeader from '../components/Landing/ParallaxedHeader';
import ScrollStopElements from '../components/Landing/ScrollStopElements';
import ParallaxedBottomBlock from '../components/Landing/ParallaxedBottomBlock';
import Features from '../components/Landing/Features/Features'
import Footer from '../components/Landing/Footer';
import {Trans, useTranslation} from "react-i18next";
import LogoEn from "../components/Common/LogoEn";

const Landing = () => {
  const [isNavOpen, setIsNavOpen] = useState(false)
  const { i18n } = useTranslation();

  useEffect(() => {
    // if (Intl.NumberFormat().resolvedOptions().locale.startsWith("ru") ||  i18n.language.startsWith("ru")) {
    //   i18n.changeLanguage("ru-Ru")
    // } else {
      i18n.changeLanguage("en")
    // }
  }, []);
  
  function openNav() {
    setIsNavOpen(!isNavOpen)
  }
  
  function changeLanguage(lang) {
    i18n.changeLanguage(lang)
  }
  
  return (
    <div className={`${isNavOpen && "h-[100vh] overflow-hidden"} w-full`}>
      <header className="flex flex-row items-center justify-between w-full  h-16 px-5 md:px-6 fixed top-0 inset-x-0 mx-auto bg-white z-[99999]">
        <div className="flex flex-row items-center justify-center gap-3">
          <svg className="sm:hidden flex" onClick={() => openNav()} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M21 7.10166H3V4.70166H21V7.10166ZM21 13.1017H3V10.7017H21V13.1017ZM21 19.1017H3V16.7017H21V19.1017Z" fill="#1A1A1A"/>
          </svg>
          {i18n.language === "ru-Ru" ? <Logo /> : <LogoEn />}
        </div>
        <div className="flex flex-row gap-2 items-center justify-center">
          {/*<p onClick={() => changeLanguage('ru-Ru')}>Ru</p>*/}
          {/*<p onClick={() => changeLanguage('en')}>En</p>*/}
          <a href="https://app.newme.io/login">
            <button className="md:flex hidden text-grey font-semibold text-[15px] leading-[24px] py-2 px-4 rounded-[41px] border-2 border-transparent hover:border-black hover:text-black">
              <Trans i18nKey="login">
                Войти
              </Trans>
            </button>
          </a>
          <a href="https://app.newme.io/register">
            <button className={`${isNavOpen ? "hidden" : "flex"} text-black font-semibold text-[15px] leading-[24px] py-2 px-4 rounded-[41px] border-2 border-black hover:bg-black hover:text-white`}>
              <Trans i18nKey="try">
                Попробовать
              </Trans>
            </button>
          </a>
        </div>
      </header>
      {isNavOpen && <nav className="absolute top-0 left-0 w-full h-full bg-white z-30 overflow-hidden">
        <div className="flex flex-row items-center justify-between h-16 px-5 md:px-6 sticky top-0 left-0 bg-white z-10">
          <div className="flex flex-row items-center justify-center gap-3">
            <svg onClick={() => openNav()} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M10.3028 11.9999L4.15137 5.84842L5.84842 4.15137L11.9999 10.3028L18.1514 4.15137L19.8484 5.84842L13.697 11.9999L19.8484 18.1514L18.1514 19.8484L11.9999 13.697L5.84842 19.8484L4.15137 18.1514L10.3028 11.9999Z" fill="#1A1A1A"/>
            </svg>
            <Logo />
          </div>
        </div>
        <div className="flex flex-col items-center justify-between h-[80%] w-full px-[20px]">
          <div className="flex flex-col items-center justify-start w-full gap-2">
            <a href="https://dev.newme.io/auth/signup"><button className="mt-[24px] text-black font-semibold text-[15px]
             leading-[24px] w-full h-[56px] py-2 px-4 rounded-[41px] border-2 border-black active:bg-black active:text-white">
              <Trans i18nKey="try">Попробовать</Trans></button></a>
            <a href="https://dev.newme.io/auth/login"><button className="text-grey font-semibold text-[15px] leading-[24px] 
            w-full h-[56px] py-2 px-4 rounded-[41px] border-2 border-transparent active:border-black active:text-black">
              <Trans i18nKey="login">Войти</Trans></button></a>
          </div>
          <div className="flex flex-col items-center justify-start">
            <button className="text-grey font-semibold text-[15px] leading-[24px] w-full h-[56px] py-2 px-4 rounded-[41px] 
            border-2 border-transparent active:border-black active:text-black">
              <Trans i18nKey="terms">Пользовательское соглашение</Trans></button>
            <button className="text-grey font-semibold text-[15px] leading-[24px] w-full h-[56px] py-2 px-4 rounded-[41px] 
            border-2 border-transparent active:border-black active:text-black">
              <Trans i18nKey="support">Написать в поддержку</Trans></button>
            <p className="text-[#A3A3A3] pt-[24px] pb-10 font-regular text-[13px] leading-[20px]">
              {new Date().getFullYear()}, <Trans i18nKey="allrights">Ньюми. Все права защищены</Trans></p>
          </div>
          </div>
      </nav>}
      <main className="relative mt-16 w-full flex flex-col items-center justify-center">
        <ParallaxedHeader />
        <video poster={Egg} className="z-10 w-full max-w-[375px] min-h-[455px] sm:max-w-[479px] sm:min-h-[560px] lg:max-w-[528px] lg:min-h-[600px] xl:max-w-[660px] xl:min-h-[740px] pb-20 overflow-hidden -m-[1px]" autoPlay loop muted playsInline>
          <source src={EggVideo} type="video/mp4; codecs=&quot;avc1.42E01E, mp4a.40.2&quot;" />
        </video>
        <ScrollStopElements />
        <Features />
        <ParallaxedBottomBlock />
        <Footer />
      </main>
    </div>
  );
};

export default Landing;
