import React from 'react';

const NewmeClubLogoRu = () => {
  return (
    <svg width="225" height="40" viewBox="0 0 225 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M29.1243 20.6782L31.0622 13.4022H37.0585L31.9031 32.6344H25.9068L27.6984 25.9799H20.7879L18.9963 32.6344H13L18.1554 13.4022H24.1517L22.2139 20.6782H29.1243Z" fill="white"/>
      <path d="M44.5965 18.6307H48.4356C53.591 18.6307 55.4557 22.8355 54.432 26.638C53.5544 29.9653 50.5563 32.6344 45.6568 32.6344H34.8707L39.9895 13.4022H45.9859L44.5965 18.6307ZM48.4722 25.9068C48.7281 24.9196 48.2894 23.713 46.7537 23.713H43.2437L42.2565 27.5155H45.9493C47.4484 27.5155 48.2528 26.7843 48.4722 25.9068Z" fill="white"/>
      <path d="M76.3227 13C82.319 13 86.8163 17.3144 84.9881 24.1152C83.7815 28.7221 79.6865 33 73.617 33C68.7541 33 64.8419 30.1846 64.5128 25.4314H62.319L60.3812 32.6344H54.3848L59.5402 13.4022H65.5366L63.6353 20.4954H65.3903C67.0356 16.4369 70.9479 13 76.3227 13ZM79.2112 23.4936C80.0155 20.4223 78.1508 18.4479 75.6645 18.4479C73.0686 18.4479 71.3501 20.4223 70.8016 22.4698C70.0338 25.3583 71.6426 27.5155 74.3483 27.5155C77.0174 27.5155 78.6627 25.468 79.2112 23.4936Z" fill="white"/>
      <path d="M98.7913 18.7038L105.117 13.4022H111.113L105.994 32.6344H99.9978C102.155 24.5174 102.74 22.6527 103.947 20.3492C103.033 21.4095 101.351 22.7989 97.0362 26.3821C94.5865 22.6892 93.8552 21.4826 93.4896 20.3126C93.4896 22.543 93.0874 24.3711 90.8571 32.6344H84.8607L89.9796 13.4022H95.2446L98.7913 18.7038Z" fill="white"/>
      <path d="M110.772 22.1042C109.2 28.0274 112.151 32.6344 117.051 32.6344C119.156 32.6344 120.964 31.9683 121.782 30.7862L121.189 32.6344H127.265L132.394 13.4247H126.252L123.569 23.4936C122.911 25.9068 121.302 27.4059 119.437 27.4059C116.732 27.4059 116.147 24.9561 116.732 22.7623L119.237 13.4037H113.094L110.772 22.1042Z" fill="white"/>
      <path d="M150.436 33.3836C148.425 33.3836 146.695 32.9574 145.246 32.1052C143.806 31.2444 142.698 30.0512 141.922 28.5256C141.155 27.0001 140.771 25.2444 140.771 23.2586C140.771 21.2472 141.159 19.483 141.935 17.966C142.719 16.4404 143.831 15.2515 145.271 14.3992C146.712 13.5384 148.425 13.108 150.411 13.108C152.124 13.108 153.624 13.4191 154.911 14.0412C156.198 14.6634 157.216 15.537 157.966 16.662C158.716 17.787 159.129 19.108 159.206 20.6251H154.067C153.922 19.6449 153.539 18.8566 152.916 18.26C152.303 17.6549 151.497 17.3523 150.5 17.3523C149.656 17.3523 148.919 17.5824 148.289 18.0427C147.666 18.4944 147.181 19.1549 146.831 20.0242C146.482 20.8935 146.307 21.9461 146.307 23.1819C146.307 24.4347 146.477 25.5001 146.818 26.3779C147.168 27.2557 147.658 27.9248 148.289 28.385C148.919 28.8452 149.656 29.0753 150.5 29.0753C151.122 29.0753 151.681 28.9475 152.175 28.6918C152.678 28.4361 153.091 28.0654 153.415 27.5796C153.747 27.0853 153.965 26.493 154.067 25.8026H159.206C159.121 27.3026 158.712 28.6236 157.979 29.7657C157.254 30.8992 156.253 31.7856 154.975 32.4248C153.696 33.064 152.183 33.3836 150.436 33.3836Z" fill="url(#paint0_linear_1818_17852)"/>
      <path d="M168.139 6.81824V33.0001H162.693V6.81824H168.139Z" fill="url(#paint1_linear_1818_17852)"/>
      <path d="M185.094 24.6393V13.3637H190.54V33.0001H185.311V29.4333H185.107C184.664 30.5839 183.926 31.5086 182.895 32.2074C181.872 32.9063 180.624 33.2557 179.149 33.2557C177.837 33.2557 176.682 32.9574 175.685 32.3609C174.688 31.7643 173.908 30.9163 173.345 29.8168C172.791 28.7174 172.51 27.4006 172.502 25.8665V13.3637H177.948V24.8949C177.956 26.054 178.267 26.9702 178.881 27.6435C179.494 28.3168 180.317 28.6535 181.348 28.6535C182.004 28.6535 182.618 28.5043 183.189 28.206C183.76 27.8992 184.22 27.4475 184.57 26.8509C184.928 26.2543 185.102 25.5171 185.094 24.6393Z" fill="url(#paint2_linear_1818_17852)"/>
      <path d="M194.998 33.0001V6.81824H200.444V16.662H200.611C200.849 16.1336 201.194 15.5966 201.646 15.0512C202.106 14.4972 202.703 14.037 203.436 13.6705C204.177 13.2955 205.098 13.108 206.197 13.108C207.629 13.108 208.95 13.483 210.16 14.233C211.371 14.9745 212.338 16.0952 213.062 17.5952C213.787 19.0867 214.149 20.9574 214.149 23.2074C214.149 25.3978 213.795 27.2472 213.088 28.7557C212.389 30.2557 211.434 31.3935 210.224 32.1691C209.023 32.9361 207.676 33.3197 206.184 33.3197C205.128 33.3197 204.229 33.1449 203.487 32.7955C202.754 32.4461 202.153 32.0072 201.684 31.4788C201.216 30.9418 200.858 30.4006 200.611 29.8552H200.368V33.0001H194.998ZM200.329 23.1819C200.329 24.3495 200.491 25.368 200.815 26.2373C201.139 27.1066 201.608 27.7841 202.221 28.2699C202.835 28.7472 203.581 28.9859 204.459 28.9859C205.345 28.9859 206.095 28.743 206.709 28.2572C207.322 27.7628 207.787 27.081 208.102 26.2117C208.426 25.3339 208.588 24.3239 208.588 23.1819C208.588 22.0484 208.43 21.0512 208.115 20.1904C207.8 19.3296 207.335 18.6563 206.721 18.1705C206.108 17.6847 205.354 17.4418 204.459 17.4418C203.572 17.4418 202.822 17.6762 202.209 18.1449C201.604 18.6137 201.139 19.2785 200.815 20.1393C200.491 21.0001 200.329 22.0143 200.329 23.1819Z" fill="url(#paint3_linear_1818_17852)"/>
      <defs>
        <linearGradient id="paint0_linear_1818_17852" x1="144.5" y1="-42" x2="240.5" y2="104" gradientUnits="userSpaceOnUse">
          <stop stopColor="#7F66FF"/>
          <stop offset="0.322639" stopColor="#F96CF4"/>
          <stop offset="0.669275" stopColor="#FDFD00"/>
          <stop offset="1" stopColor="#56FF02"/>
        </linearGradient>
        <linearGradient id="paint1_linear_1818_17852" x1="144.5" y1="-42" x2="240.5" y2="104" gradientUnits="userSpaceOnUse">
          <stop stopColor="#7F66FF"/>
          <stop offset="0.322639" stopColor="#F96CF4"/>
          <stop offset="0.669275" stopColor="#FDFD00"/>
          <stop offset="1" stopColor="#56FF02"/>
        </linearGradient>
        <linearGradient id="paint2_linear_1818_17852" x1="144.5" y1="-42" x2="240.5" y2="104" gradientUnits="userSpaceOnUse">
          <stop stopColor="#7F66FF"/>
          <stop offset="0.322639" stopColor="#F96CF4"/>
          <stop offset="0.669275" stopColor="#FDFD00"/>
          <stop offset="1" stopColor="#56FF02"/>
        </linearGradient>
        <linearGradient id="paint3_linear_1818_17852" x1="144.5" y1="-42" x2="240.5" y2="104" gradientUnits="userSpaceOnUse">
          <stop stopColor="#7F66FF"/>
          <stop offset="0.322639" stopColor="#F96CF4"/>
          <stop offset="0.669275" stopColor="#FDFD00"/>
          <stop offset="1" stopColor="#56FF02"/>
        </linearGradient>
      </defs>
    </svg>

  );
};

export default NewmeClubLogoRu;
