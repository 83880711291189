const FeatureElement = ({ title, image, description }) => {
  return (
    <div className='feature flex justify-between bg-grey/5 rounded-[24px] w-full min-h-[280px] p-8'>
      <div className='flex flex-col justify-between'>
        <p className='text-[32px] leading-[40px] font-bold max-w-[260px]'>
          {title}
        </p>
        <img
          src={image}
          className='block sm:hidden w-[128px] h-[128px] my-6 self-end object-contain'
          alt='Feature'
        />
        <p className='w-full sm:max-w-[263px] text-[16px] leading-[24px] text-grey/60'>
          {description}
        </p>
      </div>
      <img
        src={image}
        className='hidden sm:block w-[180px] h-[180px] object-contain'
        alt='Feature'
      />
    </div>
  )
}

export default FeatureElement