const FeatureImage = ({ image, className }) => {
  return (
    <img
      src={image}
      className='slideInUp md:w-[381px] w-full h-auto object-cover md:h-[409px]'
      alt='Benefit'
    />
  )
}

export default FeatureImage