import React from 'react';

const NewmeClubLogoEn = () => {
  return (
    <svg width="225" height="40" viewBox="0 0 225 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24.8318 13C28.8172 13 32.9488 15.596 31.1207 22.4333L28.3784 32.6344H22.309L25.0512 22.4333C25.9653 19.0329 23.7715 18.192 22.0896 18.192C20.1517 18.192 18.8355 19.2888 17.9945 21.9214L15.1426 32.6344H9L14.192 13.4022H18.8355L19.3473 15.4497C20.6636 13.8775 22.3821 13 24.8318 13Z" fill="white"/>
      <path d="M51.7457 24.8464H37.8517C37.6323 26.7112 39.4239 27.9909 41.5446 27.9909C43.6287 27.9909 44.9449 27.1133 45.9321 25.8336L51.2703 26.6015C49.5884 30.4771 45.3837 33 41.0692 33C34.8535 33 31.1241 28.7952 32.1844 22.6527C33.1716 17.0585 37.8151 13 43.4824 13C49.5153 13 53.4641 16.766 51.7457 24.8464ZM43.0436 17.8629C40.8133 17.8629 39.1314 19.106 38.6195 20.8611H46.3343C46.5537 19.0329 45.1277 17.8629 43.0436 17.8629Z" fill="white"/>
      <path d="M77.9011 13.4022H84.4459L73.6963 32.6344H67.5172C67.4806 25.0293 67.5172 22.287 67.8463 20.3126C67.0784 22.287 65.5793 25.0658 61.4112 32.6344H55.232L54.7567 13.4022H60.753C60.6068 21.117 60.5336 23.7861 60.1314 25.8702C60.9358 23.7495 62.2887 21.0804 66.3837 13.4022H72.4532C72.4166 20.7514 72.3801 23.2377 72.0144 25.4314C72.7457 23.457 74.1351 20.7514 77.9011 13.4022Z" fill="white"/>
      <path d="M105.251 13C110.626 13 114.429 16.1079 112.747 22.3967L110.005 32.6344H103.935L106.86 21.7386C107.665 18.777 105.727 18.192 104.484 18.192C102.509 18.192 101.339 19.5448 100.571 22.3967L97.8291 32.6344H91.7596L94.6847 21.7386C95.4525 18.8867 93.4416 18.192 92.1253 18.192C90.224 18.192 89.2002 19.4351 88.3959 22.3967L85.6536 32.6344H79.5841L82.5092 21.7386C84.1911 15.4497 87.8108 13 92.5275 13C95.4891 13 97.5001 14.0603 98.5969 15.5229C100.279 13.9506 102.18 13 105.251 13Z" fill="white"/>
      <path d="M133.231 24.8464H119.337C119.118 26.7112 120.91 27.9909 123.03 27.9909C125.114 27.9909 126.431 27.1133 127.418 25.8336L132.756 26.6015C131.074 30.4771 126.869 33 122.555 33C116.339 33 112.61 28.7952 113.67 22.6527C114.657 17.0585 119.301 13 124.968 13C131.001 13 134.95 16.766 133.231 24.8464ZM124.529 17.8629C122.299 17.8629 120.617 19.106 120.105 20.8611H127.82C128.039 19.0329 126.613 17.8629 124.529 17.8629Z" fill="white"/>
      <path d="M152.436 33.3836C150.425 33.3836 148.695 32.9574 147.246 32.1052C145.805 31.2444 144.697 30.0512 143.922 28.5256C143.155 27.0001 142.771 25.2444 142.771 23.2586C142.771 21.2472 143.159 19.483 143.935 17.966C144.719 16.4404 145.831 15.2515 147.271 14.3992C148.712 13.5384 150.425 13.108 152.41 13.108C154.124 13.108 155.624 13.4191 156.91 14.0412C158.197 14.6634 159.216 15.537 159.966 16.662C160.716 17.787 161.129 19.108 161.206 20.6251H156.067C155.922 19.6449 155.538 18.8566 154.916 18.26C154.302 17.6549 153.497 17.3523 152.5 17.3523C151.656 17.3523 150.919 17.5824 150.288 18.0427C149.666 18.4944 149.18 19.1549 148.831 20.0242C148.481 20.8935 148.307 21.9461 148.307 23.1819C148.307 24.4347 148.477 25.5001 148.818 26.3779C149.168 27.2557 149.658 27.9248 150.288 28.385C150.919 28.8452 151.656 29.0753 152.5 29.0753C153.122 29.0753 153.68 28.9475 154.175 28.6918C154.677 28.4361 155.091 28.0654 155.415 27.5796C155.747 27.0853 155.964 26.493 156.067 25.8026H161.206C161.121 27.3026 160.712 28.6236 159.979 29.7657C159.254 30.8992 158.253 31.7856 156.974 32.4248C155.696 33.064 154.183 33.3836 152.436 33.3836Z" fill="url(#paint0_linear_2003_20380)"/>
      <path d="M170.139 6.81824V33.0001H164.693V6.81824H170.139Z" fill="url(#paint1_linear_2003_20380)"/>
      <path d="M187.094 24.6393V13.3637H192.54V33.0001H187.311V29.4333H187.106C186.663 30.5839 185.926 31.5086 184.895 32.2074C183.872 32.9063 182.624 33.2557 181.149 33.2557C179.837 33.2557 178.682 32.9574 177.685 32.3609C176.687 31.7643 175.908 30.9163 175.345 29.8168C174.791 28.7174 174.51 27.4006 174.501 25.8665V13.3637H179.947V24.8949C179.956 26.054 180.267 26.9702 180.881 27.6435C181.494 28.3168 182.317 28.6535 183.348 28.6535C184.004 28.6535 184.618 28.5043 185.189 28.206C185.76 27.8992 186.22 27.4475 186.57 26.8509C186.927 26.2543 187.102 25.5171 187.094 24.6393Z" fill="url(#paint2_linear_2003_20380)"/>
      <path d="M196.998 33.0001V6.81824H202.444V16.662H202.61C202.849 16.1336 203.194 15.5966 203.646 15.0512C204.106 14.4972 204.703 14.037 205.436 13.6705C206.177 13.2955 207.098 13.108 208.197 13.108C209.629 13.108 210.95 13.483 212.16 14.233C213.37 14.9745 214.338 16.0952 215.062 17.5952C215.787 19.0867 216.149 20.9574 216.149 23.2074C216.149 25.3978 215.795 27.2472 215.088 28.7557C214.389 30.2557 213.434 31.3935 212.224 32.1691C211.022 32.9361 209.676 33.3197 208.184 33.3197C207.127 33.3197 206.228 33.1449 205.487 32.7955C204.754 32.4461 204.153 32.0072 203.684 31.4788C203.215 30.9418 202.858 30.4006 202.61 29.8552H202.367V33.0001H196.998ZM202.329 23.1819C202.329 24.3495 202.491 25.368 202.815 26.2373C203.139 27.1066 203.608 27.7841 204.221 28.2699C204.835 28.7472 205.581 28.9859 206.458 28.9859C207.345 28.9859 208.095 28.743 208.708 28.2572C209.322 27.7628 209.787 27.081 210.102 26.2117C210.426 25.3339 210.588 24.3239 210.588 23.1819C210.588 22.0484 210.43 21.0512 210.115 20.1904C209.799 19.3296 209.335 18.6563 208.721 18.1705C208.108 17.6847 207.353 17.4418 206.458 17.4418C205.572 17.4418 204.822 17.6762 204.208 18.1449C203.603 18.6137 203.139 19.2785 202.815 20.1393C202.491 21.0001 202.329 22.0143 202.329 23.1819Z" fill="url(#paint3_linear_2003_20380)"/>
      <defs>
        <linearGradient id="paint0_linear_2003_20380" x1="146.5" y1="-42" x2="242.5" y2="104" gradientUnits="userSpaceOnUse">
          <stop stopColor="#7F66FF"/>
          <stop offset="0.322639" stopColor="#F96CF4"/>
          <stop offset="0.669275" stopColor="#FDFD00"/>
          <stop offset="1" stopColor="#56FF02"/>
        </linearGradient>
        <linearGradient id="paint1_linear_2003_20380" x1="146.5" y1="-42" x2="242.5" y2="104" gradientUnits="userSpaceOnUse">
          <stop stopColor="#7F66FF"/>
          <stop offset="0.322639" stopColor="#F96CF4"/>
          <stop offset="0.669275" stopColor="#FDFD00"/>
          <stop offset="1" stopColor="#56FF02"/>
        </linearGradient>
        <linearGradient id="paint2_linear_2003_20380" x1="146.5" y1="-42" x2="242.5" y2="104" gradientUnits="userSpaceOnUse">
          <stop stopColor="#7F66FF"/>
          <stop offset="0.322639" stopColor="#F96CF4"/>
          <stop offset="0.669275" stopColor="#FDFD00"/>
          <stop offset="1" stopColor="#56FF02"/>
        </linearGradient>
        <linearGradient id="paint3_linear_2003_20380" x1="146.5" y1="-42" x2="242.5" y2="104" gradientUnits="userSpaceOnUse">
          <stop stopColor="#7F66FF"/>
          <stop offset="0.322639" stopColor="#F96CF4"/>
          <stop offset="0.669275" stopColor="#FDFD00"/>
          <stop offset="1" stopColor="#56FF02"/>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default NewmeClubLogoEn;



