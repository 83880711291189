import { NewmeLogo } from '../../assets'
import React from 'react'

const Logo = () => {
  return (
    <svg width="84" height="40" viewBox="0 0 84 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.0125 17.925L13.3665 12.8411H17.5563L13.9541 26.279H9.76434L11.0162 21.6294H6.1877L4.93587 26.279H0.746094L4.34828 12.8411H8.53806L7.18405 17.925H12.0125Z" fill="#1A1A1A"/>
      <path d="M22.3123 16.4944H24.9948C28.597 16.4944 29.8999 19.4323 29.1846 22.0893C28.5715 24.4141 26.4766 26.279 23.0532 26.279H15.5167L19.0933 12.8411H23.2831L22.3123 16.4944ZM25.0204 21.5783C25.1992 20.8885 24.8926 20.0455 23.8196 20.0455H21.3671L20.6773 22.7024H23.2576C24.305 22.7024 24.8671 22.1914 25.0204 21.5783Z" fill="#1A1A1A"/>
      <path d="M43.9692 12.5601C48.159 12.5601 51.3013 15.5747 50.0239 20.3265C49.1809 23.5455 46.3195 26.5345 42.0787 26.5345C38.6809 26.5345 35.9473 24.5674 35.7174 21.2462H34.1845L32.8305 26.279H28.6407L32.2429 12.8411H36.4327L35.1042 17.7973H36.3305C37.4801 14.9615 40.2137 12.5601 43.9692 12.5601ZM45.9874 19.8922C46.5495 17.7462 45.2466 16.3666 43.5093 16.3666C41.6955 16.3666 40.4947 17.7462 40.1115 19.1768C39.575 21.1951 40.6991 22.7024 42.5896 22.7024C44.4546 22.7024 45.6042 21.2717 45.9874 19.8922Z" fill="#1A1A1A"/>
      <path d="M59.1575 16.5455L63.5772 12.8411H67.767L64.1904 26.279H60.0006C61.5079 20.6075 61.9167 19.3046 62.7597 17.6951C62.121 18.436 60.9459 19.4068 57.9313 21.9104C56.2196 19.3301 55.7086 18.4871 55.4532 17.6695C55.4532 19.2279 55.1721 20.5053 53.6137 26.279H49.424L53.0006 12.8411H56.6794L59.1575 16.5455Z" fill="#1A1A1A"/>
      <path d="M76.3186 20.3265L78.3113 12.8411H82.6033L78.9756 26.279H75.731L75.3734 24.8484C74.4537 25.9469 73.2529 26.5601 71.5413 26.5601C68.7566 26.5601 65.8697 24.7462 67.1471 19.9688L69.0631 12.8411H73.304L71.388 19.9688C70.7493 22.3447 72.2821 22.9323 73.4573 22.9323C74.8113 22.9323 75.731 22.1659 76.3186 20.3265Z" fill="#1A1A1A"/>
    </svg>
  );
};

export default Logo;
