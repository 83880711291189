import React, { useEffect, useState, useRef } from "react";
import {Trans} from "react-i18next";

const GradientButton = ({ title, ...props }) => {
  
  const [isHovered, setIsHovered] = useState(false);
  const [startAnimation, setStartAnimation] = useState(false);
  const [endAnimation, setEndAnimation] = useState(false);

  const isHoveredRef = useRef(isHovered);
  const startAnimationRef = useRef(startAnimation);
  const endAnimationRef = useRef(endAnimation);

  useEffect(() => {
    // We need to apply the logic to all gradient buttons on the page
    const gradientButtons = document.querySelectorAll('.gradientButton');

    for (let i = 0; i < gradientButtons.length; i++) {
      gradientButtons[i].addEventListener('mouseenter', () => {
        if (startAnimationRef.current || endAnimationRef.current) {
          // If some animation already executes, we just saying that user mouse is on a button again
          setIsHovered(true);
        } else {
          // If no animations execute at that time, we start the new one
          setIsHovered(true);
          setStartAnimation(true);
          gradientButtons[i].classList.add('animate-start');
        }
      });

      // We don't want to end the animation instantly, we just saying that user's mouse left the button
      gradientButtons[i].addEventListener('mouseleave', () => {
        setIsHovered(false);
      });

      gradientButtons[i].addEventListener('animationend', () => {
        if (!isHoveredRef.current) {
          // If animation have ended and the button is not hovered, we check the type of last animation
          if (startAnimationRef.current) {
            setStartAnimation(false);

            gradientButtons[i].classList.remove('animate-start');
            // We need the next line to trigger the DOM reflow (to execute animation again)
            void gradientButtons[i].offsetWidth;
            gradientButtons[i].classList.add('animate-end');

            setEndAnimation(true);
          } else if (endAnimationRef.current) {
            // If the last animation was ending animation, we just change the state and remove the class
            setEndAnimation(false);
            gradientButtons[i].classList.remove('animate-end');
          }
        } else {
          // If button is still hovered, we just start to execute animation opposite to the last one
          if (startAnimationRef.current) {
            setStartAnimation(false);

            gradientButtons[i].classList.remove('animate-start');
            void gradientButtons[i].offsetWidth;
            gradientButtons[i].classList.add('animate-end');

            setEndAnimation(true);
          } else if (endAnimationRef.current) {
            setEndAnimation(false);

            gradientButtons[i].classList.remove('animate-end');
            void gradientButtons[i].offsetWidth;
            gradientButtons[i].classList.add('animate-start');

            setStartAnimation(true);
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    isHoveredRef.current = isHovered;
    startAnimationRef.current = startAnimation;
    endAnimationRef.current = endAnimation;
  }, [isHovered, startAnimation, endAnimation]);

  return (
    <a href="https://app.newme.io/register">
      <button
      {...props}
      className='gradientButton relative z-10 flex justify-center items-center w-[249px] h-[72px] mb-[27px] sm:mb-[40px] xl:mb-[72px] text-white font-semibold text-[18px] leading-[24px] rounded-[59px] bg-black overflow-hidden cursor-pointer'
    >
        <Trans i18nKey="trynewme">Попробовать Ньюми</Trans>
      </button>
    </a>
  );
};

export default GradientButton;
