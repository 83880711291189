import { useState, useEffect } from 'react'
import {
  SandClock,
  Stars,
  CircledStar,
  Feature1,
  Feature2,
  Feature3,
  Feature1En,
  Feature2En,
  Feature3En
} from '../../../assets'
import FeatureElement from './FeatureElement'
import FeatureImage from './FeatureImage'
import {t} from "i18next";
import {useTranslation} from "react-i18next";

const Features = () => {
  const { i18n } = useTranslation();
  const detectedLanguage = i18n.language;
  
  const [isVisible, setIsVisible] = useState(false);
  const [animationStarted, setAnimationStarted] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const element = document.getElementById('features');

      if (element) {
        const rect = element.getBoundingClientRect();
        const windowHeight = window.innerHeight || document.documentElement.clientHeight;
        setIsVisible(rect.top <= windowHeight);
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isVisible && !animationStarted) {
      const images = document.querySelectorAll('.slideInUp');
      let delay = 0;

      images.forEach((image) => {
        image.style.transitionDelay = `${delay}s`;
        delay += 0.3;
      });

      setAnimationStarted(true);
    }
  }, [isVisible, animationStarted]);

  return (
    <section className='relative w-full'>
      <div id='features' className={`features ${isVisible ? 'visible' : ''} hidden overflow-y-hidden lg:flex items-center xl:justify-center gap-2 w-full mb-[150px] px-12 xl:px-[60px]`}>
        <FeatureImage image={detectedLanguage === "ru-Ru" ? Feature1 : Feature1En} />
        <FeatureImage image={detectedLanguage === "ru-Ru" ? Feature2 : Feature2En} />
        <FeatureImage image={detectedLanguage === "ru-Ru" ? Feature3 : Feature3En} />
      </div>
      <div className='flex lg:hidden flex-col items-center gap-2 w-full px-6 mb-[120px]'>
        <FeatureElement
          title={t('Скорость x10')}
          image={SandClock}
          description={t('С Ньюми скорость создания контента для ваших социальных сетей вырастает в 10 раз')}
        />
        <FeatureElement
          title={t('Гипер — персонализация')}
          image={Stars}
          description={t('При создании контента Ньюми учитываем информацию о вас и ваших предпочтениях')}
        />
        <FeatureElement
          title={t('Обучаемый Искусственный Интеллект')}
          image={CircledStar}
          description={t('С каждым днем ИИ становится умнее, дообучаясь на данных и обратной связи от вас')}
        />
      </div>
    </section>
  )
}

export default Features