import {useRef, useEffect, useState} from 'react'
import { motion, useScroll, useTransform } from 'framer-motion'
import GradientButton from './GradientButton'
import {useTranslation} from "react-i18next";
import {Trans} from "react-i18next";

const ParallaxedHeader = () => {
  const { i18n } = useTranslation();
  const detectedLanguage = i18n.language;
  const [wordList, setWordList] = useState(['stories', 'ideas', 'posts', 'courses', 'meanings', 'yourself']);

  // Typing animation
  const textRef = useRef(null);
  const cursorRef = useRef(null);

  useEffect(() => {
    if (i18n.language.startsWith('ru')) {
      setWordList(['сторис', 'идеи', 'посты', 'курсы', 'смыслы', 'себя']);
    } else {
      setWordList(['stories', 'ideas', 'posts', 'courses', 'meanings', 'yourself']);
    }
  }, [i18n.language]);

  useEffect(() => {
    const textElement = textRef.current; // Element that holds the text
    const cursorElement = cursorRef.current; // Cursor element

    let wordIndex = 0; // Current sentence being processed
    let letterIndex = 0; // Character number of the current sentence being processed
    let interval; // Holds the handle returned from setInterval

    // Implements typing effect
    function Type() {
      // Get substring with 1 character added
      let text = wordList[wordIndex].substring(0, letterIndex + 1);
      textElement.textContent = text;
      letterIndex++;

      // If the full sentence has been displayed, start deleting the sentence after some time
      if (text === wordList[wordIndex]) {
        // Hide the cursor
        clearInterval(interval);
        setTimeout(function () {
          interval = setInterval(Delete, 100);
        }, 1000);
      }
    }

    // Implements deleting effect
    function Delete() {
      // Get substring with 1 character deleted
      let text = wordList[wordIndex].substring(0, letterIndex - 1);
      textElement.textContent = text;
      letterIndex--;

      // If the sentence has been deleted, start displaying the next sentence
      if (text === '') {
        clearInterval(interval);

        // If the current sentence was the last one, display the first one, else move to the next
        if (wordIndex === wordList.length - 1) {
          wordIndex = 0;
        } else {
          wordIndex++;
        }

        letterIndex = 0;

        // Start displaying the next sentence after some time
        setTimeout(function () {
          cursorElement.style.display = 'inline-block';
          interval = setInterval(Type, 75);
        }, 200);
      }
    }

    // Start the typing effect on load
    interval = setInterval(Type, 100);

    return () => {
      // Clean up the interval on component unmount
      clearInterval(interval);
    };
  }, [i18n.language, wordList]);
  
  // Parallax
  const { scrollYProgress } = useScroll();
  const y = useTransform(scrollYProgress, [0, 1], ['0%', '500%']);

  return (
    <motion.div
      style={{y}}
      className="w-full flex flex-col items-center justify-center z-20 max-w-[640px] lg:max-w-[1024px] mt-[24px] sm:mt-[80px] xl:mt-[120px] mb-[120px] sm:mb-[260px] md:mb-[200px]"
    >
      <div className='flex flex-col md:flex-row items-center justify-center sm:gap-3 lg:gap-4'>
        <h1 className='text-center text-[40px] leading-[44px] sm:text-[68px] sm:leading-[72px] font-bold'>
          <Trans i18nKey="create">
            Создавайте
          </Trans>
        </h1>
        <div className={`typing text-center flex items-center h-[44px] sm:h-[72px]`}>
          <span ref={textRef} className='typingText inline-block text-[40px] leading-[44px] sm:text-[68px] sm:leading-[72px] font-bold w-full'></span>
          <span ref={cursorRef} className='typingCursor inline-block bg-black w-[2px] h-[40px] sm:h-[68px]'></span>
        </div>
      </div>
      <h1 className={`sm:max-w-[1000px] text-center text-[40px] leading-[44px] sm:text-[68px] sm:leading-[72px] 
      font-bold w-full px-6 pb-8 ${detectedLanguage !== "ru-Ru" && "sm:max-w-[432px] max-w-[300px]"}`}>
        <Trans i18nKey='withTheHelp'>с помощью искусственного интеллекта</Trans></h1>
      <GradientButton />
    </motion.div>
  );
};

export default ParallaxedHeader
