import { useState, useEffect, useRef } from "react";
import { Trans, useTranslation } from "react-i18next";
import "particles.js/particles";

import NewmeClubLogoEn from "../../assets/icons/NewmeClubLogoEn";
import NewmeClubLogoRu from "../../assets/icons/NewmeClubLogoRu";

import { FooterGradient } from '../../assets';

const FooterLink = ({ children, ...props }) => {
  return (
    <a
      className="text-white font-regular text-center text-[13px] leading-[20px] hover:underline cursor-pointer"
      {...props}
    >
      {children}
    </a>
  );
};

const Footer = () => {
  const { i18n } = useTranslation();
  const detectedLanguage = i18n.language;
  
  const [isTextVisible, setIsTextVisible] = useState(false);
  const textRef = useRef(null);
  const observerRef = useRef(null);
  const particlesJS = window.particlesJS;

  particlesJS.load('particles-js', 'particles.json');

  useEffect(() => {
    observerRef.current = new IntersectionObserver(([entry]) =>
      setIsTextVisible(entry.isIntersecting)
    );
  }, []);

  useEffect(() => {
    observerRef.current.observe(textRef.current);

    return () => {
      observerRef.current.disconnect();
    };
  }, [textRef]);
  
  return (
    <footer className="relative z-[9999] flex flex-col items-center justify-center w-full pt-[37px] lg:pt-[88px] rounded-t-3xl overflow-hidden bg-[#1A1A1A]">
      {/* Newme logo */}
      <div className='flex items-center w-[135px] h-[24px] lg:w-[225px] lg:h-[40px]'>
        {detectedLanguage === "ru-Ru" ? <NewmeClubLogoRu /> : <NewmeClubLogoEn />}
      </div>

      {/* Main text */}
      <div className="z-30 hidden lg:flex flex-col text-[30px] lg:text-[56px] leading-[38px] lg:leading-[64px] font-semibold text-white text-center sm:max-w-[876px] md:max-w-[1000px] w-full px-[24px] py-12">
        <Trans i18nKey="newmeassist">Ньюми не только поможет с созданием контента, но и поддержит на всех этапах запуска проекта</Trans>
        <div className='flex justify-center items-center'>
          <span className='mr-[15px]'>&mdash;</span>
          <span ref={textRef} className={`w-fit h-full animated-text ${isTextVisible ? 'visible' : ''}`}>
            <Trans i18nKey="fromidea"> от идеи до продвижения</Trans></span>
        </div>
      </div>
      <p className="z-30 lg:hidden w-full px-[24px] sm:px-[48px] pt-[55px] pb-[40px] sm:pb-[24px] lg:pb-[43px] xl:pb-[48px] text-[30px] sm:text-[48px] leading-[38px] sm:leading-[56px] font-medium text-white text-center">
        <Trans i18nKey="newmeassist">Ньюми не только поможет с созданием контента, но и поддержит на всех этапах запуска проекта</Trans> — <span className='text-[#D252FF]'><Trans i18nKey="fromidea">от идеи до продвижения</Trans></span>
      </p>

      {/* Telegram button */}
      <button className="z-30 group flex flex-row gap-2 text-black font-semibold text-[18px] leading-[24px] bg-white rounded-[59px] py-5 px-8 hover:text-white hover:bg-[#4DA9FF] tgButtonTransition">
        <span><Trans i18nKey="telegram">Следить в Телеграм</Trans></span>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path className='duration-300 fill-[#1A1A1A] group-hover:fill-[#FFF]' fillRule="evenodd" clipRule="evenodd" d="M21.0283 4.09896L18.0085 19.6987C18.0085 19.6987 17.5862 20.7806 16.4248 20.2613L12.1281 16.8862L9.37181 19.4611C9.37181 19.4611 9.15629 19.6286 8.92062 19.5236L9.4484 14.7407L9.46628 14.755L9.47746 14.6573C9.47746 14.6573 17.2062 7.53906 17.523 7.23613C17.8398 6.9332 17.7341 6.86829 17.7341 6.86829C17.7552 6.50051 17.164 6.86829 17.164 6.86829L6.92227 13.5323L6.92232 13.5324L6.92217 13.5324L2.65658 12.0611C2.65658 12.0611 2.00197 11.8231 1.93862 11.3039C1.87527 10.7846 2.67767 10.5033 2.67767 10.5033L19.6346 3.68783C19.6346 3.68783 21.0283 3.06042 21.0283 4.09896Z"/>
        </svg>
      </button>

      {/* Bottom content */}
      <div className="relative z-10 flex flex-col lg:flex-row justify-between items-center gap-y-6 w-full max-w-[1536px] px-16 pt-[112px] pb-8 sm:pt-[120px] sm:pb-10 xl:pt-[132px] xl:pb-8">
        <div className="flex lg:flex-row flex-col items-center justify-center gap-8">
          <p className="text-white opacity-60 font-regular text-[13px] leading-[20px] text-center">
            {new Date().getFullYear()} <Trans i18nKey="allrights">Ньюми. Все права защищены</Trans>
          </p>
          <FooterLink target="_blank" href="https://billing.newme.io/static/user-agreement.pdf">
            <Trans i18nKey="terms">Пользовательское соглашение</Trans>
          </FooterLink>
        </div>
        <FooterLink href="mailto:support@newme.io">
          <Trans i18nKey="support">Написать в поддержку</Trans>
        </FooterLink>
      </div>

      {/* Floating white particles element */}
      <div id="particles-js"></div>

      {/* Background gradient */}
      <img className='footerGradient' src={FooterGradient} alt='Footer gradient' />
    </footer>
  )
}

export default Footer