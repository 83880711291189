import { useState, useEffect } from 'react'
import {
  Benefit1,
  Benefit1En,
  Benefit2,
  Benefit2En,
  Benefit3,
  Benefit3En,
  JustStartButton,
  JustStartEn
} from '../../assets'
import {Trans, useTranslation} from "react-i18next";

export default function ScrollStopElements() {
  const { i18n } = useTranslation();
  const detectedLanguage = i18n.language;
  
  // State for active words and text color
  const [activeWord, setActiveWord] = useState(0);
  const [isTextBlack, setIsTextBlack] = useState(true);

  // State for active benefit
  const [activeBenefit, setActiveBenefit] = useState(1);
  
  useEffect(() => {
    function animateByScroll () {
      // Animation for words
      if (window.scrollY > 1200 && window.scrollY < 1700) {
        setActiveWord(1);
        setIsTextBlack(false);
      } else if (window.scrollY >= 1700 && window.scrollY < 2200) {
        setActiveWord(2);
        setIsTextBlack(false);
      } else if (window.scrollY >= 2200 && window.scrollY < 2700) {
        setActiveWord(3);
        setIsTextBlack(false);
      } else {
        setActiveWord(0);
        setIsTextBlack(true);
      }

      // Animation for benefits
      if (window.scrollY < 4000) {
        setActiveBenefit(1)
      } else if (window.scrollY >= 4000 && window.scrollY < 4750) {
        setActiveBenefit(2);
      } else {
        setActiveBenefit(3);
      }
    }
    
    window.addEventListener('scroll', animateByScroll);
    return () => {
      window.removeEventListener('keydown', animateByScroll);
    };
  }, [activeWord]);

  return (
    <div className='scrollStopElement w-full'>
      <div className='h-[2500px]'>
        <div className='sticky z-10 top-1/3 sm:top-1/4 inset-x-0 bottom-0 flex flex-col justify-center items-center w-full'>
          <p className={`w-auto max-w-[620px] lg:max-w-[924px] px-5 text-center text-[#A3A3A3] font-bold text-[30px] sm:text-[48px] lg:text-[56px] leading-[38px] sm:leading-[56px] lg:leading-[64px] duration-300 ease-in-out ${isTextBlack ? 'text-black' : ''}`}>
            <Trans i18nKey="newmeHelps">Ньюми помогает </Trans><span className={activeWord === 1 ? "text-[#315FFF] duration-300 ease-in-out !opacity-100" : ""}>
            <Trans i18nKey="experts">экспертам</Trans></span>, <span className={activeWord === 2 ? "text-[#0BD6E3] duration-300 ease-in-out opacity-100" : ""}>
            <Trans i18nKey="producers">продюсерам</Trans></span>, <span className={activeWord === 3 ? "text-[#F875FF] duration-300 ease-in-out opacity-100" : ''}>
            <Trans i18nKey="bloggers">блогерам </Trans></span>
            <Trans i18nKey="andOther"> и другим креаторам в создании оригинальных идей и контента с помощью нейросетей</Trans>
          </p>
          <div className='w-[209px] h-[56px] mt-2'>
            <img className='w-full h-full object-contain' src={detectedLanguage === "ru-Ru" ? JustStartButton : JustStartEn} alt='Just start button' />
          </div>
        </div>
      </div>
      <div className='h-[2500px]'>
        <div className="sticky z-10 top-[18%] inset-x-0 bottom-0 flex justify-center items-center w-full lg:flex-row flex-col md:mt-[210px] mt-[136px] pb-[120px] lg:pb-[150px] xl:pb-[250px] gap-[32px] sm:gap-[40px] md:gap-[32px] lg:gap-[80px] px-[24px] sm:px-[48px]">
          <div className="flex flex-col gap-[32px] lg:gap-[64px] items-center">
            <p className={`font-bold w-[270px] sm:w-[370px] text-center lg:text-left text-[24px] sm:text-[32px] leading-[32px] 
            sm:leading-[40px] duration-300 ease-in-out ${activeBenefit === 1 ? "opacity-100" : "opacity-40"} ${detectedLanguage !== "ru-Ru" && "max-w-[170px] sm:max-w-[260px]"}`}>
              <Trans i18nKey="chooseTasks">Выбирайте задания для Ньюми</Trans></p>
            <p className={`font-bold w-[270px] sm:w-[370px] text-center lg:text-left text-[24px] sm:text-[32px] leading-[32px] 
            sm:leading-[40px] duration-300 ease-in-out ${activeBenefit === 2 ? "opacity-100" : "opacity-40"} ${detectedLanguage !== "ru-Ru" && "max-w-[170px] sm:max-w-[260px]"}`}>
              <Trans i18nKey="personalize">Персонализируйте ваши запросы</Trans></p>
            <p className={`font-bold w-[270px] sm:w-[370px] text-center lg:text-left text-[24px] sm:text-[32px] leading-[32px] 
            sm:leading-[40px] duration-300 ease-in-out ${activeBenefit === 3 ? "opacity-100" : "opacity-40"} ${detectedLanguage !== "ru-Ru" && "max-w-[170px] sm:max-w-[260px]"}`}>
              <Trans i18nKey="getcontent">Получайте готовый контент</Trans></p>
          </div>
          <div className='shrink-0 w-[332px] h-[281px] sm:w-[490px] sm:h-[416px] xl:w-[588px] xl:h-[500px] rounded-[24px]'>
            <img src={detectedLanguage === "ru-Ru" ? Benefit1 : Benefit1En} alt="benefit1" className={`${activeBenefit === 1 ? 'visible opacity-100 h-[270px] sm:h-[400px] xl:h-[480px]' : 'invisible h-0 opacity-0'} benefit w-[327px] sm:w-[483px] xl:w-[580px] object-cover rounded-[24px]`}/>
            <img src={detectedLanguage === "ru-Ru" ? Benefit2 : Benefit2En} alt="benefit1" className={`${activeBenefit === 2 ? 'visible opacity-100 h-[270px] sm:h-[400px] xl:h-[480px]' : 'invisible h-0 opacity-0'} benefit w-[327px] sm:w-[483px] xl:w-[580px] object-cover rounded-[24px]`}/>
            <img src={detectedLanguage === "ru-Ru" ? Benefit3 : Benefit3En} alt="benefit1" className={`${activeBenefit === 3 ? 'visible opacity-100 h-[281px] sm:h-[416px] xl:h-[500px]' : 'invisible h-0 opacity-0'} benefit w-[332px] sm:w-[490px] xl:w-[588px] object-cover rounded-[24px]`}/>
          </div>
        </div>
      </div>
    </div>
  );
}